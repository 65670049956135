<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-05 17:02:10
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2022-12-15 10:28:37
 * @FilePath: \web\src\views\invoicing\electronicInvoice\specialUse.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<add-invoice :title='title' :typeId='typeId' :category='category'/>
</template>

<script>
import AddInvoice from '../modules/addInvoice.vue'
export default {
	name: 'ordinary',
	components: {
		AddInvoice,
	},
  data(){
    return{
        title: '增值税电子专用发票',
        typeId : '02',
        category : '1',
    }
  },
  methods: {
		
  }
}
</script>